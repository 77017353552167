import React from "react";
import styled from "styled-components";

import ModeEditIcon from "../svg-icons/mode-edit-24-px.svg";
import ContentCopyIcon from "../svg-icons/content-copy-24-px.svg";
import DeleteIcon from "../svg-icons/delete-24-px.svg";
import CallSplitIcon from "../svg-icons/call-split-24-px.svg";
import DoNotDisturbAltIcon from "../svg-icons/do-not-disturb-alt-24-px.svg";
import HighlightOffIcon from "../svg-icons/highlight-off-24-px.svg";
import FormatColorTextIcon from "../svg-icons/format-color-text-24-px.svg";
import StrikethroughIcon from "../svg-icons/strikethrough-s-24-px.svg";
import FontDownloadIcon from "../svg-icons/font-download-24-px.svg";
import BorderColorIcon from "../svg-icons/border-color-24-px.svg";
import CloseIcon from "../svg-icons/close-24-px.svg";
import LinkIcon from "../svg-icons/link-24-px.svg";
import PrevIcon from "../svg-icons/prev-24-px.svg";
import NextIcon from "../svg-icons/next-24-px.svg";
import MoveIcon from "../svg-icons/icon_move-24px.svg";
import DragHorizontalIcon from "../svg-icons/actions-navigation-horizontal-dragable-24.svg";
import EditorDeleteIcon from "../svg-icons/editor-delete-24-px.svg";
import BasicInfoOutlineIcon from "../svg-icons/basic-info-outline-24-px.svg";
import KeyboardBackspaceIcon from "../svg-icons/keyboard_backspace-24px.svg";
import FolderIcon from "../svg-icons/folder.svg";
import MySurveyIcon from "../svg-icons/mysurvey.svg";
import SharedIcon from "../svg-icons/sharewithme.svg";
// import GroupIcon from "../svg-icons/group.svg";
// import AddIcon from "../svg-icons/ButtonsTertiary.svg";
// import FilterIcon from "../svg-icons/filter_list_black.svg";
import NextOptionIcon from "../svg-icons/Nexr_Black_20px.svg";
import openInFullFillIcon from "../svg-icons/open_in_full_FILL_24px.svg";
import CheckIcon from "../svg-icons/Check_wght300_10px.svg";
import LockIcon from "../svg-icons/basic-lock-person-24-px.svg";
import HelpIcon from "../svg-icons/help-24px.svg";
import ScrollIcon from "../svg-icons/scrool.svg";

function _overwritePathColor(Svg) {
  return props => (
    <Wrapper color={props.color} {...props}>
      <Svg />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  display: inline-block;
  color: ${props => props.color || "#444"};
  width: ${props => props.size || 24}px;
  height: ${props => props.size || 24}px;
  & svg {
    width: ${props => props.size || 24}px;
    height: ${props => props.size || 24}px;
    & path,
    polygon {
      fill: currentColor;
    }
  }
`;

const EditMode = _overwritePathColor(ModeEditIcon);
const ContentCopy = _overwritePathColor(ContentCopyIcon);
const Delete = _overwritePathColor(DeleteIcon);
const HighlightOff = _overwritePathColor(HighlightOffIcon);
const DoNotDisturbAlt = _overwritePathColor(DoNotDisturbAltIcon);
const CallSplit = _overwritePathColor(CallSplitIcon);
const FormatColorText = _overwritePathColor(FormatColorTextIcon);
const Strikethrough = _overwritePathColor(StrikethroughIcon);
const FontDownload = _overwritePathColor(FontDownloadIcon);
const BorderColor = _overwritePathColor(BorderColorIcon);
const Close = _overwritePathColor(CloseIcon);
const Link = _overwritePathColor(LinkIcon);
const Prev = _overwritePathColor(PrevIcon);
const Next = _overwritePathColor(NextIcon);
const Move = _overwritePathColor(MoveIcon);
const DragHorizontal = _overwritePathColor(DragHorizontalIcon);
const EditorDelete = _overwritePathColor(EditorDeleteIcon);
const BasicInfoOutline = _overwritePathColor(BasicInfoOutlineIcon);
const KeyboardBackspace = _overwritePathColor(KeyboardBackspaceIcon);
const Folder = _overwritePathColor(FolderIcon);
const MySurvey = _overwritePathColor(MySurveyIcon);
const Shared = _overwritePathColor(SharedIcon);
const NextOption = _overwritePathColor(NextOptionIcon);
const openInFullFill = _overwritePathColor(openInFullFillIcon);
const Check = _overwritePathColor(CheckIcon);
const Lock = _overwritePathColor(LockIcon);
const Help = _overwritePathColor(HelpIcon);
const Scroll = _overwritePathColor(ScrollIcon);

export {
  EditMode,
  ContentCopy,
  Delete,
  HighlightOff,
  DoNotDisturbAlt,
  CallSplit,
  FormatColorText,
  Strikethrough,
  FontDownload,
  BorderColor,
  Close,
  Link,
  Prev,
  Next,
  Move,
  DragHorizontal,
  EditorDelete,
  BasicInfoOutline,
  KeyboardBackspace,
  Folder,
  MySurvey,
  Shared,
  NextOption,
  openInFullFill,
  Check,
  Lock,
  Help,
  Scroll,
};
